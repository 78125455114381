.hero {
  background-image: linear-gradient(rgba($dark-blue,0.2),rgba($dark-blue,0.2)),
    url('/wp-content/uploads/2018/02/21-SL-8261-8229-copy.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  text-align: center;

  &:before, &:after {
    content: '';
    display: block;
  }

  &:before {
    height: 140px;
    @include tablet {
      height: 200px;
    }
    @include laptop {
      min-height: 150px;
      height: 27vh;
    }
  }

  &:after {
    height: 240px;
    @include phablet {
      height: 200px;
    }
    @include tablet {
      height: 260px;
    }
    @include laptop {
      min-height: 200px;
      height: 32vh;
    }
  }

  .cta-box {
    background-color: rgba($brand-primary,0.8);
    padding: 60px 20px;
    position: relative;
    @include laptop {
      padding: 80px 20px;
    }

    h1 {
      color: $white;
      font-size: 42px;
      margin: 0 0 15px;
      line-height: 1.1;
      @include tablet {
        font-size: 48px;
      }
      @include laptop {
        font-size: 52px;
      }
      @include desktop {
        font-size: 60px;
      }
    }

    p {
      font-weight: 200;
      font-size: 30px;
      color: $white;
      line-height: 1.2;
      margin: 0 0 30px;
      @include tablet {
        font-size: 32px;
        margin: 0 0 40px;
      }
      @include laptop {
        margin: 0 0 50px;
      }
      @include desktop {
        font-size: 35px;
      }
    }

    .btn {
      padding: 22px;
      @include laptop {
        min-width: 240px;
      }
    }

    &:before {
      position: absolute;
      content: '';
      // z-index: 101;
      border: 1px solid $white;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
  }
}


.strapline {
  background-color: rgba($white,0.65);
  @include stretch();
  top: auto;
  padding: 30px 0;
  @include laptop {
    padding: 40px 0;
  }
  @include desktop {
    padding: 40px 0 50px;
  }
  p {
    margin: 0;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 400;
    @include tablet {
      padding: 0;
      font-size: 22px;
    }
  }
}



.why-panel {
  background-color: $light-blue;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  @include laptop {
    flex-wrap: nowrap;
  }

  h1 {
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    @include wide {
      max-width: 100%;
    }
  }

  .img-panel {
    background-image: linear-gradient(rgba(57,90,122,.2),rgba(57,90,122,.2)), url(/wp-content/uploads/2021/09/poontoonboathome.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex: 0 0 100%;
    height: 240px;
    @include tablet {
      height: 360px;
    }
    @include laptop {
      height: auto;
      flex: 0 0 50%;
      min-height: 100%;
    }
    @include desktop {
      flex: 0 0 48%;
    }
    @include wide {
      flex: 0 0 44%;
    }
  }

  .panel-content {
    flex-grow: 1;
  }

  .why-block {
    margin-bottom: 20px;
    @include tablet {
      display: flex;
      justify-content: flex-start;
    }
    @include laptop {
      width: calc(100% + 160px);
      margin-left: -160px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    h3, p {
      color: $white;
    }

    h3 {
      text-align: center;
      padding: 0 15px;
      margin: 0 0 20px;
      @include tablet {
        margin: 0;
        flex: 0 0 32.6%;
      }
    }

    p {
      padding: 0 15px;
      font-weight: 200;
      margin: 0;
      @include tablet {
        flex: 0 0 65%;
      }
      @include wide {
        flex: 0 0 50%;
      }
    }

    .icon-block {
      padding: 30px 0 0;
      text-align: center;
      position: relative;
      background-color: rgba($dark-blue, 0.2);
      @include tablet {
        padding: 30px 0;
        background-color: rgba($dark-blue, 0.125);
        flex: 0 0 160px;
      }
      @include laptop {
        background: rgba($light-blue,0.95);
      }
      img {
        height: 60px;
        @include tablet {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .why-text {
      padding: 30px 0;
      background-color: rgba($dark-blue, 0.2);
      text-align: center;
      @include tablet {
        flex-grow: 1;
        text-align: left;
        justify-content: flex-start;
        align-items: center;
        display: flex;
      }
    }
  }
}


.boat-rental-panel {
  text-align: center;

  .boat-rental {
    width: 600px;

    ul {
        margin-bottom: 0;
        &:nth-of-type(1n+2) {
            margin-top: 10px;
        }
    }

    h2, h3, li {
      color: $white;
    }
  }

  .btn {
    margin-top: 45px;
  }
}



.services-panel {
  background-color: rgba($light-blue,0.3);
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  display: flex;
  @include laptop {
    flex-wrap: nowrap;
  }

  h1 {
    margin-bottom: 70px;
  }

  .img-panel {
    background-image: linear-gradient(rgba($brand-primary,0.1), rgba($brand-primary,0.1)),
      url(/wp-content/uploads/2020/01/IMG_2716.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex: 0 0 100%;
    height: 240px;
    @include tablet {
      height: 360px;
    }
    @include laptop {
      min-height: 100%;
      height: auto;
      flex: 0 0 30%;
    }
    @include desktop {
      flex: 0 0 34%;
    }
  }

  .panel-content {
    padding-bottom: 0;
    flex-grow: 1;
  }

  .services-block {
    width: 100%;
    background-color: rgba($white, 0.5);
    padding-bottom: 90px;

    .service-row {
      // 1px offset to allow .service-col negative margin
      padding: 1px 10px 0;
      max-width: 1260px;
      margin: 0 auto;
      @include tablet {
        justify-content: space-between;
        display: flex;
      }
      @include desktop {
        padding: 0 20px;
      }
    }

    .service-col {
      padding: 0 10px;
      margin-top: 40px;
      max-width: 388px;
      margin-left: auto;
      margin-right: auto;
      @include tablet {
        margin-bottom: 0;
        margin-top: -35px;
        flex: 0 0 calc(1 / 3 * 100%);
      }
      @include wide {
        padding: 0 15px;
      }
      &:first-of-type {
        margin-top: -35px;
      }
      .title-block {
        display: flex;
        background-color: $light-blue;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        padding: 10px;
        @include tablet {
          margin-bottom: 35px;
        }
        h3 {
          margin: 0;
          font-weight: 400;
          line-height: 1.1;
          color: $white;
          font-size: 20px;
          @include hd {
            font-size: 22px;
          }
        }
        img {
          margin-right: 10px;
          height: 50px;
          @include hd {
            margin-right: 15px;
            height: 60px;
          }
        }
      }
      p {
        padding: 0 10px;
        @include hd {
          padding: 0 30px;
        }
      }
    }
  }

  .btn {
    margin-top: 30px;
    @include tablet {
      margin-top: 40px;
    }
  }

}



.vacation-rental-panel {
  text-align: center;

  .rental-footer {
    margin-top: 60px;
  }

  hr {
    width: 500px;
    max-width: 100%;
    border-color: rgba($white,0.5);
    margin: 30px auto;
  }

  .vacation-rental {
    // margin-top: 50px;
    @include tablet {
      margin-top: 0;
    }

    &.first {
      margin-bottom: 0;
      margin-top: 0;
    }

    &.last {
      display: none;
      @include laptop {
        display: block;
      }
    }
  }

  p.powered-by {
    color: $white;
    margin-top: 35px;
    img {
      margin-left: 8px;
    }
  }
}
.frontLakePanel {
    background: $dark-blue;
    color: $white;
    padding: 50px 0;
    text-align: center;
    h1,p {
        margin: 0;
        padding: 0;
    }
    h1 {
        font-size: 36px;
        margin-bottom: 15px;
    }
    @include laptop{
        text-align: left;
        h1 {
            white-space: nowrap;
            margin-right: 60px;
            margin-bottom: 0;
        }
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }
}
