.btn {
  font: 400 20px $sans;
  padding: 22px;
  color: $white;
  border-radius: 0;
  border: none;
  position: relative;
  line-height: 1;
  transition: 0.3s ease-in-out;
  max-width: 100%;
  min-width: 224px;
  @include laptop {
    font-size: 22px;
  }

  &:after {
    content: '';
    position: absolute;
    transition: 0.3s ease-in-out;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%);
    width: calc(100% - 14px);
    height: calc(100% - 14px);
    border: 1px solid $white;
  }

  &:hover, &:focus {
    &:after {
      width: 100%;
      height: 100%;
    }
  }

  &.btn-primary {
    background-color: $dark-blue;
    color: $white;
    &:after {
      border-color: $white;
    }
    &:hover, &:focus {
      background-color: lighten($dark-blue, 5);
    }
  }

  &.btn-white {
    background-color: $white;
    color: $brand-primary;
    &:after {
      border-color: $brand-primary;
    }
    &:hover, &:focus {
      background-color: lighten($white, 15);
    }
  }

  &.btn-small {
    min-width: 0;
    padding: 15px;
    font-size: 18px;
    @include wide {
      font-size: 22px;
    }
    &:after {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
    }
    &:hover, &:focus {
      &:after {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.btn-medium {
    min-width: 240px;
  }

  &.btn-large {
    min-width: 280px;
  }

  .fa {
    font-size: 20px;
  }
}
