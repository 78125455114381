.footer-cta {
  background-color: rgba($light-blue,0.1);
  text-align: center;
  .panel-content {
    padding-left: 15px;
    padding-right: 15px;
    @include laptop {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cta-title {
      @include laptop {
        margin-right: 40px;
      }
      @include desktop {
        margin-right: 80px;
      }
      @include wide {
        margin-right: 160px;
      }
    }

    .cta-btn {
      @include desktop {
        margin-right: 50px;
      }
    }

    h1 {
      color: $dark-blue;
      line-height: 1.1;
      margin: 0;
      font-size: 32px;
      @include desktop {
        font-size: 38px;
      }
      @include wide {
        font-size: 42px;
      }
    }

    p {
      font-weight: 200;
      color: $dark-blue;
      font-size: 26px;
      margin-bottom: 30px;
      @include laptop {
        margin-bottom: 0;
      }
      @include desktop {
        font-size: 28px;
      }
      @include wide {
        font-size: 32px;
      }
    }
  }
}

footer.content-info {
  .footer-content {
    width: 480px;
    margin: 0 auto;
    padding: 50px 0 40px;
    text-align: center;
    max-width: 100%;
    @include tablet {
      width: auto;
      text-align: left;
    }

    .footer-col {
      @include tablet {
        display: flex;
        justify-content: space-between;
      }
    }

    a {
      transition: 0.3s ease-in-out;
      &:hover, &:focus {
        color: $black;
      }
    }

    .brand {
      display: block;
      margin-bottom: 25px;
      @include tablet {
        margin-top: 14px;
        margin-bottom: 15px;
      }
      @include laptop {
        margin-top: 28px;
      }
      img {
        width: 220px;
        @include desktop {
          width: auto;
        }
      }
    }

    .nav {
      @include laptop {
        margin-top: 22px;
      }
      li {
        a {
          display: block;
          transition: 0.3s ease-in-out;
          color: $dark-blue;
          font-size: 16px;
          padding: 5px 8px;
          letter-spacing: -0.5px;
          @include tablet {
            padding: 5px 0;
          }
          @include desktop {
            font-size: 17px;
          }
        }
      }
    }

    .footer-contact {
      margin-top:2px;
      margin-bottom: 10px;
      @include laptop {
        margin-top: 22px;
      }
      p {
        font-size: 16px;
        color: $dark-blue;
        margin: 0 0 2px;
        span {
          display: inline-block;
          padding: 3px 0;
          @include tablet {
            padding: 5px 0;
          }
        }
        a {
          color: $dark-blue;
          font-size: 18px;
          margin-right: 6px;
          @include tablet {
            font-size: 20px;
            margin-left: 6px;
            margin-right: 0;
          }
        }
        @include desktop {
          font-size: 18px;
        }
      }

      &.phone {
        margin: 0;
        padding: 3px 0;
        @include tablet {
          padding: 5px 0;
        }
      }
    }

    .nav {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include tablet {
        display: block;
      }
    }

    .footer-map {
      margin-top: 30px;
      @include tablet {
        margin-top: 20px;
      }
      @include laptop {
        margin-top: 0;
      }

      #footer_map_canvas {
        height: 200px!important;
        @include laptop {
          height: 140px!important;
        }
      }
    }

  }

  .copyright {
    text-align: center;
    padding: 8px 0;
    .copy {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include laptop {
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      div, a {
        font-size: 12px;
        color: $black;
      }
    }
  }
}
