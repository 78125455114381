// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper {
  position: relative;
  z-index: 101;
  .gform_body {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .gfield {
        margin-bottom: 15px;

        label {
          color: $white;
          font-weight: 400;
        }

        input, textarea {
          width: 100%;
          padding: 10px;
        }

        textarea {
          height: 180px;
        }

        &:last-of-type {
          label {
            display: none;
          }
        }
      }
    }
  }
  .gform_footer {
    padding: 0;
    display: inline-block;
    @extend .btn;
    @extend .btn-small;
    @extend .btn-white;

    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: transparent;
      font-size: 18px;
      color: $brand-primary;
      border: none;
      padding: 15px;
      position: relative;
      z-index: 100;
    }
  }
}
.bordered-box {
    color: $white;
}
