.hero.hero-landingpage {
  border-bottom: 20px solid $white;

  &:before, &:after {
    content: '';
    display: block;
  }

  &:before {
    min-height: 0;
    height: 160px;
  }

  &:after {
    min-height: 0;
    height: 80px;
  }

  .cta-box {
    padding: 30px;
    flex-direction: column;
    @include laptop {

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn-white {
      margin: 10px 0;

      @include tablet {
        margin: 0 10px;
      }
    }

    h1 {
      color: $white;
      margin: 0 0 20px;
      font-size: 36px;
      @include laptop {
        margin-bottom: 15px;
        font-size: 32px;
      }
      @include desktop {
        font-size: 36px;
      }

    }

    .btn {
      font-size: 18px;
      @include tablet {
        font-size: 20px;
      }
      @include laptop {
        font-size: 22px;
      }
    }

  }
}

.page-content {
  background-color: rgba($light-blue,0.3);
  border-top: none;
  padding: 70px 0;

  h2 {
    margin: 0 0 15px;
  }

  .bordered-img, .bordered-img-link {
    margin: 20px 0;
  }

  .boat-rental {
    margin-bottom: 35px;
    .bordered-img, .bordered-img-link {
      margin: 0;
    }
  }
}

.no-click-link {
    cursor: default;

    &:hover, &:focus, &:active {
        &:after {
            display: none;
            visibility: hidden;
            opacity: 0;
        }
    }
}

.vacation-rental {
  text-align: center;
  &.first {
    margin-bottom: 50px;
    @include tablet {
      margin-bottom: 0;
    }
  }

  h4, p {
    color: $white;
  }

  .bordered-img {
    margin-bottom: 30px;
  }

  h4 {
    line-height: 1.1;
    margin: 0 0 5px;
  }

  p {
    margin: 0;
  }

  .vacation-listings-panel & {
    margin-top: 45px;
    @include tablet {
      margin-top: 25px;
    }

    &.first {
      margin-bottom: 0;
    }
  }
}


.boat-rental {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  &.first {
    margin-bottom: 45px;
    @include laptop {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 28px;
    @include tablet {
      font-size: 30px;
    }
    @include laptop {
      font-size: 26px;
    }
    @include desktop {
      font-size: 30px;
    }
  }

  .horizontal-list {
    margin-top: 30px;
    // padding: 0 15px;
    @include tablet {
      padding: 0 60px;
    }
    @include laptop {
      padding: 0 30px;
    }
    @include desktop {
      padding: 0 60px;
    }
    li {
      font-weight: 700;
      font-size: 15px;
      @include mobile {
        font-size: 16px;
      }
      @include tablet {
        font-size: 18px;
      }
    }
  }

}


.contact-map {
  border-top: 20px solid $white;

  iframe {
    height: 420px;
    width: 100%;
    border: 0;
  }
}

.bordered-box {
  background-color: $dark-blue;
  position: relative;
  padding: 35px;

  &.first {
    margin-bottom: 30px;
    @include laptop {
      margin-bottom: 0;
    }
  }

  &:before {
    position: absolute;
    content: '';
    // z-index: 101;
    border: 1px solid $white;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }

  .box-content {
    position: relative;
    z-index: 101;
  }

  p, li, h2, h3, h4 {
    color: $white;
  }
}

.reservation-section {
  &#pontoonBoats {
    margin-bottom: 60px;
  }
  h2 {
    color: $white;
    margin: 0 0 30px;
  }
}


.reservaton {
  position: relative;
  margin-bottom: 35px;

  img {
    margin-bottom: 25px;
    @include laptop {
      margin-bottom: 0;
    }
  }

  h3 {
    margin: 0 0 8px;
    @include laptop {
      letter-spacing: -0.5px;
    }
    letter-spacing: 0;
  }

  p.description {
    @include laptop {
      padding-top: 30px;
    }
  }

  .color {
    margin-bottom: 0;
  }

  .color, .length {
    font-size: 18px;
  }

  a.btn {
    @include laptop {
      position: absolute;
      top: -5px;
      right: 10px;
    }
  }

  a.btn.waiver {
    @include laptop {
      position: absolute;
      top: 50px;
      right: 10px;
    }
  }
}
