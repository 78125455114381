.mobileNav {
  @include stretch;
  position: fixed;
  z-index: 1001;
  background-color: $mobileNavBkgColor;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;

  &:before {
    position: absolute;
    content: '';
    border: 1px solid $white;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  .nav {
    position: relative;
    z-index: 1000;
    margin-top: 40px;
    @include mobile {
      margin-top: 0;
    }
    li {
      &.hide-header {
        display: block!important;
      }
      a {
        color: $white;
        font-size: 19px;
        padding: 5px 8px;
        display: block;
        text-transform: uppercase;
        @media screen and (min-width: 375px) {
          font-size: 21px;
        }
        @include landscape {
          font-size: 17px;
          padding: 4px 8px;
        }
        @include tablet {
          padding: 6px 8px;
          font-size: 30px;
        }
      }
    }
  }

  &.mobileNav-active {
    visibility: visible;
    opacity: 1;
  }
}

.mobileNavBody-active {
  max-height: 100vh;
  overflow: hidden;

  header.banner {
    > .container {
      position: relative;
    }
  }
  .hamburger {
    position: absolute;
    z-index: 2001;
    top: 35px;
    right: 35px;
  }
}
