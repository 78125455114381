* {
  outline: 0!important;
  @include smooth-fonts();
}

.test-size {
  visibility: hidden;
  font-size: 1px;
  @media screen and (min-width: 375px) {
    font-size: 2px;
  }
  @include tablet {
    font-size: 3px;
  }
  @include laptop {
    font-size: 4px;
  }
  @include desktop {
    font-size: 5px;
  }
}


html, body {
  background-color: $white;
  font: 300 16px $sans;
  @include smooth-fonts();
}

.rich-card-image {
  display: none!important;
}

.row {
  &.row-centered {
    justify-content: center;
  }
}

.container-fluid {
  @include laptop {
    max-width: 985px;
  }
  @include desktop {
    max-width: 1195px;
  }
  @include wide {
    max-width: 1435px;
  }
  @include hd {
    max-width: 1685px;
  }
}

.hide-inline-laptop {
  @include laptop {
    display: none;
  }
  @include desktop {
    display: inline;
  }
}

.show-inline-laptop {
  display: none;
  @include laptop {
    display: inline;
  }
  @include desktop {
    display: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font: 700 42px $serif;

  &.section-header {
    color: $white;
    margin: 0 0 35px;
    line-height: 1.2;
    position: relative;
    text-align: center;
    &:after {
      content: url('/wp-content/uploads/wp-images/knot-yellow-icon-01.png');
      display: block;
    }

    &.alt {
      color: $dark-blue;
      &:after {
        content: url('/wp-content/uploads/wp-images/knot-blue-icon-01.png');
      }
    }
  }
}

h2 {
  font: 300 30px $sans;
  margin: 0 0 35px;
  line-height: 1.2;
}

h3 {
  font: 300 22px $sans;
}

h4 {
  font: 700 18px $sans;
}

p, li {
  font: 300 16px $sans;
}

a {
  &:hover, &:focus {
    text-decoration: none;
  }
}

.no-wrap {
  white-space: nowrap;
}

.horizontal-list {
  list-style-type: none;
  justify-content: space-between;
  display: flex;
  padding: 0;
}

.section-panel {
  border-top: 20px solid $white;
  .panel-content {
    padding: 70px 0;
    @include laptop {
      padding: 90px 0;
    }
  }

  &.water-panel {
    background: linear-gradient(rgba($dark-blue,0.7),rgba($dark-blue,0.7)),
      url(/wp-content/uploads/wp-images/water-bkg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include laptop {
      background-attachment: fixed;
    }
  }
}

.bordered-img {
    max-width: 100%;
  position: relative;
  display: inline-block;
  &:before {
    position: absolute;
    content: '';
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border: 1px solid $white;
    box-shadow: 0px 0px 0px 20px rgba($white,0.2);
  }
}

.bordered-img-link {
  @extend .bordered-img;
  &:after {
    background-color: $dark-blue;
    transition: 0.6s ease-in-out;
    position: absolute;
    opacity: 0.4;
    content: '';
    top: 21px;
    left: 21px;
    right: 21px;
    bottom: 21px;
    z-index: 101;
    @include laptop {
      opacity: 0;
    }
  }

  .link-text {
    position: absolute;
    text-align: center;
    transition: 0.6s ease-in-out;
    top: 50%;
    left: 21px;
    width: calc(100% - 42px);
    transform: translateY(-50%) scale(1.0, 1.0);
    z-index: 102;
    color: rgba($white, 1);
    font-size: 18px;
    font-weight: 700;
    @include mobile {
      font-size: 20px;
    }
    @include tablet {
      font-size: 22px;
    }
    @include laptop {
      visibility: hidden;
      color: rgba($white, 0);
    }
  }

  &:hover, &:focus {
    &:after {
      opacity: 0.65;
      visibility: visible;
    }
    .link-text {
      color: rgba($white, 1);
      visibility: visible;
    }
  }

}

.text-center {
  text-align: center;
}
