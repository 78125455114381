header.banner {
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba($white, 0.7);

  .hide-header {
    display: none!important;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .brand {
    display: block;
    img {
      width: 192px;
      @include laptop {
        width: 150px;
      }
      @include desktop {
        width: 192px;
      }
    }
  }

  .nav-primary {
    .nav {
      display: flex;
      // justify-content: center;
      li {
        margin: 0 6px;
        @include desktop {
          margin: 0 8px;
        }
        @include wide {
          margin: 0 15px;
        }
        a {
          position: relative;
          display: block;
          font-weight: 500;
          color: $black;
          font-size: 15px;
          padding: 10px 0px;
          @include desktop {
            padding: 10px 0px;
          }
          @include wide {
            padding: 10px 0px;
            font-size: 16px;
          }

          &:after {
            content: '';
            outline: 1px solid transparent;
            position: absolute;
            visibility: hidden;
            bottom: 0;
            left: 50%;
            width: 0;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            height: 2px;
            background: $black;
            -webkit-transition: .5s;
            transition: .5s;
          }

          &:hover, &:focus {
            &:after {
              width: 100%;
              visibility: visible;
            }
          }

        }

        &.active {
          a:after {
            width: 100%;
            visibility: visible;
          }
        }

        &.menu-phone {
          a:after {
            content: none;
          }
        }

      }
    }
  }

}

.maine-boating-temporary-certificate,
.page-template-template-waiver,
.page-template-template-contact,
.page-template-template-reservations,
.page-template-template-vacation-rentals,
.page-template-default,
.page-id-23,
.page-id-439 {
  header.banner {
    position: relative;
  }
}
.page-id-439 a.btn {
  text-wrap: wrap;
}
