// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #2F62AB;
$light-blue:            #85A5CC;
$dark-blue:             #395A7A;
$black:                 #292927;
$white:                 #F7F5F2;
$yellow:                #F8D16C;

// Fonts
$sans:                 'filson-soft', sans-serif;
// Thin      100
// Light     200
// Book      300
// Regular   400
// Medium    500
// Bold      700
$serif:                'essonnes-text', serif;
// Bold      700


// Mobile Nav
$mobileNavBkgColor: rgba($dark-blue,0.9);

// Hamburger
$button_width: 50px;     // width of button area
$button_height: 50px;     // height of button area
$bar_thickness: 4px;     // thickness of button bars
$bar_roundness: 0;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 10px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $dark-blue;
$hamburger_active: $white;

// Breakpoint variables
$width_mobile:  480px;
$width_phablet: 540px;
$width_tablet:  768px;
$width_laptop:  992px;
$width_desktop: 1200px;
$width_wide:    1440px;
$width_hd:      1690px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin landscape { @media (min-device-width : 320px) and (max-device-width : 736px) and (orientation : landscape)  { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin wide { @media (min-width: #{$width_wide}) { @content; } }
@mixin hd { @media (min-width: #{$width_hd}) { @content; } }

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin stretch {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}

@mixin smooth-fonts() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
